import * as React from 'react'
import { colors } from './colors'
import { Text } from '../../elementals/Text'
import { formatNumber } from '../../../utils/number'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import { SquareRectangle } from '../../elementals/Icons/SquareRectangle'
import { Square } from '../../elementals/Icons/Square'
import { LiveUpdate } from './live-update'
import { useHashParam } from './use-hash-param'
import { Voter } from './types'
import { usernames } from './login'
import { debounce, groupBy, mapValues } from 'lodash'

type Props = {
  lastUpdated: number
  retrigger: () => void
  allData: Array<Voter> | undefined
  showOnlyWithComments: boolean
  showOnlyWithCustomComments: boolean
  showDone: boolean
  showReserved: boolean
  setShowDone: React.Dispatch<React.SetStateAction<boolean>>
  setShowReserved: React.Dispatch<React.SetStateAction<boolean>>
  setShowOnlyWithComments: React.Dispatch<React.SetStateAction<boolean>>
  setShowOnlyWithCustomComments: React.Dispatch<React.SetStateAction<boolean>>
}

export const Header = ({
  lastUpdated,
  retrigger,
  allData,
  showOnlyWithComments,
  showOnlyWithCustomComments,
  showDone,
  showReserved,
  setShowOnlyWithComments,
  setShowOnlyWithCustomComments,
  setShowDone,
  setShowReserved,
}: Props) => {
  const [username] = useHashParam('user')
  const [inputSearch, setInputSearch] = React.useState('')
  const [_, setSearch] = useHashParam('search')

  const debouncedSetSearch = React.useRef(debounce(setSearch, 1000))

  const userDialog = React.useRef<HTMLDialogElement | null>()

  const reservedHouseholdsByUser = usernames
    .map(otherUsername => {
      const totalReservationsByCurrentUser = allData?.filter(
        voter => voter.reserviertVon === otherUsername,
      )
      const groupedByHousehold = groupBy(
        totalReservationsByCurrentUser,
        person => `${person.ort}_${person.strasse}_${person.hausnummer}_${person.adresszusatz}`,
      )
      return {
        reservedBy: otherUsername,
        totalReservedHouseholds: Object.values(
          mapValues(groupedByHousehold, persons => persons.length > 0),
        ).filter(Boolean).length,
        doneReservedHouseholds: Object.values(
          mapValues(groupedByHousehold, persons =>
            persons.some(person => person.istAbgehandeltWorden === 1),
          ),
        ).filter(Boolean).length,
      }
    })
    .filter(({ totalReservedHouseholds }) => totalReservedHouseholds !== 0)
    .sort((a, b) => b.totalReservedHouseholds - a.totalReservedHouseholds)

  const allDataByHousehold = groupBy(
    allData,
    person => `${person.ort}_${person.strasse}_${person.hausnummer}_${person.adresszusatz}`,
  )

  return (
    <div
      style={{
        position: 'sticky',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        top: 0,
        backgroundColor: colors.pageBackground,
        paddingLeft: 8,
        zIndex: 10,
        gap: 4,
      }}
    >
      <div>
        {translate({ translations, path: ['households'] })}{' '}
        <Text style={{ color: colors.text.besuchte }}>
          {formatNumber(
            Object.values(allDataByHousehold).filter(persons =>
              persons.every(person => person.istAbgehandeltWorden === 1),
            ).length ?? 0,
          )}
        </Text>
        /
        {formatNumber(
          Object.values(
            groupBy(
              allData,
              person => `${person.strasse}_${person.hausnummer}_${person.adresszusatz}`,
            ),
          ).filter(persons => persons.length > 0).length ?? 0,
        )}
      </div>
      <input
        type="text"
        style={{ width: 'auto', color: colors.text.default, zoom: '100%' }}
        value={inputSearch}
        onChange={async event => {
          debouncedSetSearch.current(event.target.value)
          setInputSearch(event.target.value)
        }}
        placeholder="Search..."
      />
      <button
        style={{ paddingTop: 0, paddingBottom: 0, minHeight: 24, maxHeight: 24, zoom: '120%' }}
        onClick={() => userDialog.current?.showModal()}
      >
        <b>{translate({ translations, path: ['choose'] })}</b>
      </button>
      <dialog
        ref={node => {
          userDialog.current = node
        }}
        onClick={() => {
          userDialog.current?.close()
        }}
        style={{ marginTop: 8, fontSize: '0.66em' }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}
          onClick={event => {
            event.stopPropagation()
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '1.33em',
              marginBottom: 8,
              marginTop: 4,
            }}
          >
            <Text style={{ color: colors.text.user }}>{username}</Text>
          </div>
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={() => setShowDone(prev => !prev)}
          >
            {showDone ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showVisited'] })}
            <span />
          </button>{' '}
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={() => setShowReserved(prev => !prev)}
          >
            {showReserved ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showReservedByOther'] })}
            <span />
          </button>
          <button
            onClick={async () => {
              await retrigger()
              userDialog.current?.close()
            }}
          >
            {translate({
              translations,
              path: ['showMyReservations'],
              passed:
                reservedHouseholdsByUser.find(({ reservedBy }) => reservedBy === username)
                  ?.totalReservedHouseholds ?? 0,
            })}
          </button>
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={async () => {
              setShowOnlyWithComments(prev => !prev)
              await retrigger()
              userDialog.current?.close()
            }}
          >
            {showOnlyWithComments ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showOnlyWithComments'] })}
            <span />
          </button>
          <button
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            onClick={async () => {
              setShowOnlyWithCustomComments(prev => !prev)
              await retrigger()
              userDialog.current?.close()
            }}
          >
            {showOnlyWithCustomComments ? <SquareRectangle /> : <Square />}
            {translate({ translations, path: ['showOnlyWithCustomComments'] })}
            <span />
          </button>
          <button
            onClick={async () => {
              await retrigger()
              userDialog.current?.close()
            }}
            autoFocus
          >
            <LiveUpdate lastUpdated={lastUpdated} />
          </button>
          <div style={{ marginTop: 8 }}>
            <ol>
              {reservedHouseholdsByUser.map(
                ({ doneReservedHouseholds, reservedBy, totalReservedHouseholds }) => (
                  <li
                    key={reservedBy}
                    style={{
                      color: reservedBy === username ? colors.text.user : undefined,
                      listStyleType: 'unset',
                    }}
                  >
                    {reservedBy}: {formatNumber(doneReservedHouseholds)}/
                    <Text
                      style={{
                        color: reservedBy === username ? undefined : colors.text.eu,
                      }}
                    >
                      {formatNumber(totalReservedHouseholds)}
                    </Text>
                  </li>
                ),
              )}
            </ol>
          </div>
        </div>
      </dialog>
    </div>
  )
}
