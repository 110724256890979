import * as React from 'react'
import { Text } from '../../elementals/Text'
import { translate } from '../../../utils/translations'
import * as translations from './translations'
import { colors } from './colors'
import { Line } from './line'
import { useHashParam } from './use-hash-param'
import { Header } from './header'
import { Voter } from './types'
import { xhr } from '../../../xhr'
import { useAuthToken } from './login'
import { besuchenNotwendigTags, besuchenUnnötigTags } from './tags'
import { groupBy, mapValues } from 'lodash'

const useVoters = (authToken: string | undefined) => {
  const [{ data, lastUpdated }, setData] = React.useState<{
    data: Array<Voter> | undefined
    lastUpdated: number
  }>({ data: undefined, lastUpdated: 0 })

  const trigger = React.useCallback(async () => {
    if (authToken) {
      const response = await xhr.get('/api/voters', {
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
      })
      setData({ data: response.data, lastUpdated: new Date().valueOf() })
    }
  }, [setData, authToken])

  React.useEffect(() => {
    trigger()
  }, [trigger])

  return {
    data,
    lastUpdated,
    isLoading: data === undefined,
    retrigger: trigger,
  }
}

const AddressLine = ({ style, ...props }: React.ComponentProps<'div'>) => (
  <div
    style={{
      lineHeight: 1.3,
      backgroundColor: colors.lineBackground,
      borderRadius: 4,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 4,
      paddingBottom: 4,
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      ...style,
    }}
    {...props}
  />
)

let isPausing = false

let hasScrolledToLeftOffPosition = false
export const Page = () => {
  React.useEffect(() => {
    document.title = translate({ translations, path: ['gw'] })
  }, [])

  const authToken = useAuthToken()

  const { data, lastUpdated, retrigger } = useVoters(authToken)
  const [username] = useHashParam('user')
  const [scroll, setScroll] = useHashParam('scroll')
  const [search] = useHashParam('search')

  const lowercaseSearch = search?.toLocaleLowerCase()

  const allData = lowercaseSearch
    ? (data ?? []).filter(
        voter =>
          voter.ort.toLocaleLowerCase().includes(lowercaseSearch) ||
          voter.strasse.toLocaleLowerCase().includes(lowercaseSearch) ||
          voter.name.toLocaleLowerCase().includes(lowercaseSearch) ||
          voter.reserviertVon?.toLocaleLowerCase().includes(lowercaseSearch) ||
          String(voter.geburtsjahr).includes(lowercaseSearch) ||
          (voter.istEU && 'EU' === lowercaseSearch) ||
          (voter.adresszusatz &&
            voter.adresszusatz.toLocaleLowerCase().includes(lowercaseSearch)) ||
          voter.tags.some(tag => tag.toLocaleLowerCase().includes(lowercaseSearch)),
      )
    : data ?? []

  React.useEffect(() => {
    const onScroll = () => {
      if (!isPausing) {
        setTimeout(() => {
          setScroll(String(window.scrollY))
          isPausing = false
        }, 1000)

        isPausing = true
      }
    }

    if (!hasScrolledToLeftOffPosition) {
      setTimeout(() => {
        if (scroll) {
          hasScrolledToLeftOffPosition = true
          window.scrollTo({ top: Number(scroll) })
        }
      }, 2000)
    }

    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [scroll])

  const [showDone, setShowDone] = React.useState(true)
  const [showReserved, setShowReserved] = React.useState(true)
  const [showOnlyWithComments, setShowOnlyWithComments] = React.useState(false)
  const [showOnlyWithCustomComments, setShowOnlyWithCustomComments] = React.useState(false)

  const tags = (allData ?? [])
    .flatMap(({ tags }) => tags ?? [])
    .reduce((acc, current) => {
      const index = acc.findIndex(({ tag }) => tag === current)
      if (index !== -1) {
        acc[index] = { ...acc[index], occurrences: acc[index].occurrences + 1 }
      } else {
        acc.push({ tag: current, occurrences: 1 })
      }
      return acc
    }, [] as Array<{ occurrences: number; tag: string }>)
    .sort((a, b) => {
      const sort = b.occurrences - a.occurrences
      return sort === 0 ? b.tag.localeCompare(a.tag) : sort
    })
    .map(({ tag }) => tag)

  const dataForUser = allData?.filter(
    ({ istAbgehandeltWorden, reserviertVon, tags }) =>
      (showDone ? true : !istAbgehandeltWorden) &&
      (showReserved ? true : [null, '', username].includes(reserviertVon)) &&
      (showOnlyWithComments ? tags.length > 0 : true) &&
      (showOnlyWithCustomComments
        ? tags.filter(
            tag => !besuchenNotwendigTags.includes(tag) && !besuchenUnnötigTags.includes(tag),
          ).length > 0
        : true),
  )

  const openPeople = allData?.filter(({ istAbgehandeltWorden }) => istAbgehandeltWorden === 0)
  const isOrtDone = openPeople?.length === 0
  const isRestReserved =
    (openPeople?.length ?? 0) > 0 &&
    openPeople?.every(
      ({ reserviertVon }) =>
        reserviertVon !== '' && reserviertVon !== null && reserviertVon !== username,
    )

  const dataForUserGroupedByPlace = mapValues(
    groupBy(dataForUser ?? [], voter => voter.ort),
    votersByOrt =>
      mapValues(
        groupBy(votersByOrt, voter => voter.strasse),
        votersByStrasse =>
          mapValues(
            groupBy(votersByStrasse, voter => voter.hausnummer),
            votersByHausnummer => groupBy(votersByHausnummer, voter => voter.adresszusatz),
          ),
      ),
  )

  return (
    <div
      style={{
        backgroundColor: colors.pageBackground,
        color: colors.text.default,
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        zoom: '150%',
      }}
    >
      <Header
        allData={allData}
        lastUpdated={lastUpdated}
        retrigger={retrigger}
        setShowDone={setShowDone}
        setShowOnlyWithComments={setShowOnlyWithComments}
        setShowOnlyWithCustomComments={setShowOnlyWithCustomComments}
        setShowReserved={setShowReserved}
        showDone={showDone}
        showOnlyWithComments={showOnlyWithComments}
        showOnlyWithCustomComments={showOnlyWithCustomComments}
        showReserved={showReserved}
      />
      <datalist id="tags">
        {tags.map(tag => (
          <option key={tag}>{tag}</option>
        ))}
      </datalist>
      {(isOrtDone || isRestReserved) && (
        <div
          style={{
            position: 'sticky',
            top: 10,
            backgroundColor: colors.pageBackground,
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
            justifyContent: 'center',
            rowGap: 8,
            alignItems: 'center',
            padding: 16,
          }}
        >
          {!showReserved && isRestReserved && (
            <button
              onClick={() => {
                setShowReserved(true)
                setShowDone(false)
              }}
            >
              {translate({ translations, path: ['showReservedByOther'] })}
            </button>
          )}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2px',
        }}
      >
        {Object.entries(dataForUserGroupedByPlace).map(([ort, byStraße]) => {
          return (
            <div key={ort}>
              <AddressLine style={{ position: 'sticky', top: 31, zIndex: 4 }}>{ort}</AddressLine>
              <div>
                {Object.entries(byStraße).map(([straße, byHausnummer]) => {
                  return (
                    <div key={straße}>
                      <AddressLine style={{ position: 'sticky', top: 53, zIndex: 3 }}>
                        {straße}
                      </AddressLine>
                      <div>
                        {Object.entries(byHausnummer).map(([hausnummer, byAdresszusatz]) => (
                          <div key={hausnummer}>
                            <div style={{ position: 'absolute', left: 8, zIndex: 0 }}>{straße}</div>
                            <div
                              style={{
                                position: 'sticky',
                                top: 56.5,
                                zIndex: 3,
                                paddingLeft: straße.length + 2 + 'ch',
                              }}
                            >
                              {hausnummer}
                            </div>
                            <div>
                              {Object.entries(byAdresszusatz).map(([adresszusatz, voters]) => (
                                <div key={adresszusatz}>
                                  {adresszusatz !== 'null' && (
                                    <>
                                      <div style={{ position: 'absolute', left: 8, zIndex: 0 }}>
                                        {straße} {hausnummer}
                                      </div>
                                      <div
                                        style={{
                                          position: 'sticky',
                                          top: 56.5,
                                          zIndex: 3,
                                          paddingLeft:
                                            straße.length + 2 + hausnummer.length + 1 + 'ch',
                                          color: colors.text.hausnummer,
                                        }}
                                      >
                                        /{adresszusatz}
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    {voters.map(voter => (
                                      <Line
                                        key={voter.id}
                                        {...voter}
                                        retrigger={retrigger}
                                        username={username}
                                        authToken={authToken}
                                        alwaysShowComments={showOnlyWithCustomComments}
                                      />
                                    ))}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
        {/*ortDataForUser?.map(voter => (
          <Line
            key={voter.id}
            {...voter}
            retrigger={retrigger}
            username={username}
            authToken={authToken}
            showOrt={showOrt}
            alwaysShowComments={showOnlyWithCustomComments}
          />
        ))*/}
      </div>
    </div>
  )
}
