import * as React from 'react'
import { Checkbox } from '../../elementals/Chooser'
import { colors } from './colors'
import { Text } from '../../elementals/Text'
import { xhr } from '../../../xhr'
import { Voter } from './types'
import * as translations from './translations'
import { translate } from '../../../utils/translations'
import { Pending } from '../../elementals/Icons/Pending'
import { Group } from '../../elementals/Group'
import { uniq } from 'lodash'
import { besuchenUnnötigTags, Tags } from './tags'

type Props = Voter & {
  retrigger: () => void
  username: string | undefined
  authToken: string | undefined
  alwaysShowComments: boolean
}

export const Line = ({
  sprengel,
  nummer,
  istAbgehandeltWorden,
  id,
  istEU,
  reserviertVon,
  geburtsjahr,
  tags,
  name,
  retrigger,
  username,
  authToken,
  alwaysShowComments,
}: Props) => {
  const [isReservedPending, setReservedPending] = React.useState(false)
  const [isLineDonePending, setLineDonePending] = React.useState(false)
  const [showTagsInput, setShowTagsInput] = React.useState(false)
  const [tagInput, setTagInput] = React.useState('')

  const besuchenUnnötig = besuchenUnnötigTags.some(tag => tags.includes(tag))

  const userOrNobodyReserved =
    username && (reserviertVon === username || reserviertVon === null || reserviertVon === '')

  const submitTags = async () => {
    setLineDonePending(true)
    await xhr.patch(
      '/api/voters',
      { id, tags: uniq((tags ?? []).concat(tagInput)).sort((a, b) => b.localeCompare(a)) },
      { headers: { Authorization: 'Bearer ' + authToken } },
    )
    setTagInput('')
    await retrigger()
    setLineDonePending(false)
  }

  return (
    <div
      title={`Sprengel ${sprengel}, Nummer ${nummer}`}
      style={{
        ...(istAbgehandeltWorden === 1 ? { opacity: 0.5 } : undefined),
        lineHeight: 1.3,
        backgroundColor:
          istAbgehandeltWorden === 1 ? colors.lineBackgroundDone : colors.lineBackground,
        borderRadius: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 4,
        paddingBottom: 4,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Checkbox
        style={{ flexGrow: 1 }}
        id={`abgehandelt-${id}`}
        disabled={!userOrNobodyReserved || besuchenUnnötig}
        checked={istAbgehandeltWorden === 0 ? false : true}
        icon={isLineDonePending ? <Pending /> : undefined}
        onChange={async event => {
          setLineDonePending(true)
          await xhr.patch(
            '/api/voters',
            { id, istAbgehandeltWorden: event.target.checked },
            { headers: { Authorization: 'Bearer ' + authToken } },
          )
          await retrigger()
          setLineDonePending(false)
        }}
      >
        <Text>{name} </Text>
        <Text style={{ color: colors.text.alter }}>~{new Date().getFullYear() - geburtsjahr}</Text>
        {istEU === 1 && (
          <>
            {' '}
            <Text style={{ color: colors.text.eu, whiteSpace: 'nowrap' }}>
              {translate({ translations, path: ['eu-citizen'] })}
            </Text>
          </>
        )}
        {istAbgehandeltWorden === 0 && !besuchenUnnötig && !userOrNobodyReserved && (
          <>
            {' '}
            {translate({ translations, path: ['reservedBy'] })}{' '}
            <Text style={{ color: colors.text.besuchenUnnötig }}> {reserviertVon}</Text>
          </>
        )}
      </Checkbox>
      {((istAbgehandeltWorden === 0 && userOrNobodyReserved && tags.length > 0) ||
        alwaysShowComments) && (
        <Tags
          tags={tags}
          authToken={authToken}
          id={id}
          retrigger={retrigger}
          setLineDonePending={setLineDonePending}
          showTagsInput={showTagsInput}
          istAbgehandeltWorden={istAbgehandeltWorden === 1}
          alwaysShowComments={alwaysShowComments}
        />
      )}
      {istAbgehandeltWorden === 0 && userOrNobodyReserved && (
        <div style={{ display: 'flex', alignItems: 'center', columnGap: 4 }}>
          {showTagsInput ? (
            <Group style={{ flexBasis: 'min-content', marginRight: 4 }}>
              <input
                type="text"
                value={tagInput}
                onChange={event => setTagInput(event.target.value)}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    submitTags()
                  }
                }}
                style={{ width: '20%', minWidth: 100 }}
                list="tags"
                placeholder={translate({ translations, path: ['comments'] })}
                autoFocus
              />
              <button type="button" onClick={submitTags}>
                +
              </button>
            </Group>
          ) : (
            <button onClick={() => setShowTagsInput(true)}>≡</button>
          )}
          {!besuchenUnnötig && (
            <Checkbox
              checked={(reserviertVon ?? '') !== ''}
              icon={isReservedPending ? <Pending /> : undefined}
              onChange={async event => {
                setReservedPending(true)
                await xhr.patch(
                  '/api/voters',
                  { id, reserviertVon: event.target.checked ? username : '' },
                  { headers: { Authorization: 'Bearer ' + authToken } },
                )
                await retrigger()
                setReservedPending(false)
              }}
            >
              {reserviertVon ? (
                <>
                  {translate({ translations, path: ['reservedBy'] })}{' '}
                  <Text style={{ color: colors.text.user }}>{reserviertVon}</Text>
                </>
              ) : (
                translate({ translations, path: ['reserve'] })
              )}
            </Checkbox>
          )}
        </div>
      )}
    </div>
  )
}
